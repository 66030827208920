<template>
  <div>
    <canvas id="doge-canvas" />
    <div class="row channel__row">
      <div class="column1">
        <div
          id="player"
          class="brimePlayer"
        />
        <div>
          <div id="channel"> <a @click="scrolldiv"><b-avatar
                               size="50"
                               :src="`https://content.brimecdn.com/brime/user/${channelInfo.owners}/avatar`"
                               variant="light-primary"
                               class="badge-minimal"
                             /></a>
            <span
              style="vertical-align: top; color: white; text-transform: capitalize;"
              class="channelName"
            >
              <img
                v-if="verified"
                src="https://beta.brimelive.com/brime_verified.png"
                style="padding-left: 5px;"
              > {{ channelInfo.channel }} <span style="vertical-align: top; opacity: 0.5;"> |<span
                id="viewcount"
                style="vertical-align: center; font-size: 15px;"
                class="viewcount"
              /> VOD: {{ getDate(vodInfo.startDate) }}</span></span>
            <div
              class="streamCategory"
              style="margin-left: 55px; padding-top: -10px;"
            >
              <router-link
                :to="`/category/${category.slug}`"
              >{{ category.name }}</router-link> &bull;
              <span class="streamTitle">
                {{ vodInfo.stream.title }}
              </span>
            </div>
          </div><div class="actionButtons">
          <b-button
              v-if="isChannelOwner && !vodInfo.downloadReady"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="float-right"
              size="sm"
              variant="primary"
              target="_blank"
              @click="downloadVod()"
            ><feather-icon
              icon="DownloadIcon"
              class="mr-40 pt-0 pb-0"
            />
              Request Download
            </b-button>
            <b-button
              v-if="isChannelOwner && vodInfo.downloadReady"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="float-right"
              size="sm"
              variant="success"
              target="none"
              :href="vodInfo.downloadLink"
            ><feather-icon
              icon="DownloadIcon"
              class="mr-40 pt-0 pb-0"
            />
              Download MP4
            </b-button>
            <b-button
              v-if="isChannelOwner"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="float-right"
              size="sm"
              variant="danger"
              target="_blank"
              @click="deleteVod(selectedClip._id)"
            ><feather-icon
              icon="TrashIcon"
              class="mr-40 pt-0 pb-0"
            />
              Delete
            </b-button><b-button
              v-if="monetized && !isSubscribed"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="primary"
              style="line-height:14px;"
              class="float-right"
              @click="openCheckout()"
            >Subscribe
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.clipModal
              size="sm"
              variant="primary"
              class="float-right"
              @click="clipIt"
            >
              <feather-icon
                icon="ScissorsIcon"
                class="mr-50 clipIcon"
              /><span
                class="align-middle"
                style="line-height:14px;"
              >Create Clip</span>
            </b-button> -->
          </div></div>
        <div
          id="tabSection"
          class="tabSection"
        >
          <b-tabs>
            <b-tab
              active
              title="Clips"
            >
              <b-card-text>
                <section
                  id="card-images"
                  class="vodSection"
                >
                  <b-row>
                    <b-col
                      v-for="item in channelClips.clips"
                      :key="item._id"
                      md="5"
                      lg="4"
                      xl="3"
                    ><b-card
                      v-b-modal.clipPlayerModal
                      class="mb-3"
                      @click="sendInfo(item)"
                    >
                      <div class="vod__image">
                        <img
                          v-if="item.clipThumbnailUrl"
                          width="100%"
                          :src="item.clipThumbnailUrl"
                        >
                        <img
                          v-if="!item.clipThumbnailUrl"
                          width="100%"
                          src="https://content.brimecdn.com/brime/assets/missing_thumbnail.png"
                        >
                        <div class="vod__duration">
                          {{ getDurationClip(item.sectionStart, item.sectionEnd) }}
                        </div>
                      </div>
                      <div
                        id="vodTitle"
                        class="vod__title"
                      >
                        {{ item.stream.title }}
                      </div>
                      <div
                        class="vod__category"
                      >
                        {{ item.stream.category.name }}
                      </div>
                      <div class="vod__date">
                        {{ getAgoClip(item.sectionStart) }}
                      </div>
                      <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
                    </b-card></b-col>
                  </b-row>
                </section>
              </b-card-text>
            </b-tab>
            <b-tab title="Vods">
              <b-card-text>
                <section
                  id="card-images"
                  class="vodSection"
                >
                  <b-row>
                    <b-col
                      v-for="item in channelVods.vods"
                      :key="item._id"
                      md="5"
                      lg="4"
                      xl="3"
                    ><a
                      :href="`/videos/${item._id}`"
                    >
                      <b-card
                        class="mb-3"
                      >
                        <div class="vod__image">
                          <img
                            width="100%"
                            :src="item.vodThumbnailUrl.replace('https://content.brimecdn.com/brime-vods/', 'https://vod.brimecdn.com/n/idmrpurnoj34/b/brime-vods/o/').replace('content.brimecdn.com', 'hls.brimecdn.com')"
                          >
                          <div class="vod__duration">
                            {{ getDuration(item.startDate, item.endDate) }}
                          </div>
                        </div>
                        <div
                          id="vodTitle"
                          class="vod__title"
                        >
                          {{ item.stream.title }}
                        </div>
                        <div
                          class="vod__category"
                        >
                          {{ item.stream.category.name }}
                        </div>
                        <div class="vod__date">
                          {{ getAgo(item.startDate) }}
                        </div>
                        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
                      </b-card></a></b-col>
                  </b-row>
                </section>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="column chat__column">
        <ul
          id="chat-thread"
          class="chat-thread"
        />
        <b-form
          id="chat-form"
          method="post"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="chatInput">
            <b-form-input
              id="message"
              ref="message22"
              v-model="message"
              type="text"
              class="form-control"
              name="message"
              placeholder="Send a message"
              autocomplete="off"
              autofocus
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                class="trigger"
                style="margin-left: 0; margin-right: 0; padding-left: 10px; padding-right: 10px;"
                @click.prevent="focusInput()"
              >
                <feather-icon
                  icon="SmileIcon"
                  class="mr-0 pt-0 pb-0"
                />
              </b-button>
            </b-input-group-append>
            <button hidden>
              Send
            </button>
          </b-input-group>
        </b-form>
      </div>
      <!-- <form @submit.prevent="sendMessage" id="chat-form" method="post"><input type="text" class="form-control" name="message" id="message" v-model="message" placeholder="Send a message" autocomplete="off"><button hidden>Send</button></form> -->
    </div>
    <b-modal
      id="subscribe"
      centered
      :title="'Subscribe to ' + channel"
      ok-only
      ok-title="Cancel"
      size="lg"
      button-size="sm"
    >
      <Sub />
    </b-modal>
    <b-modal
      id="clipModal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      title="Brime Clip ™"
      @hide="clipDone = false, clipVideoUrl='', clipID=''"
    >
      <b-card-text><img
                     v-if="clipGenerating === true"
                     class="loadingClip"
                     src="https://us-central-1-s3.netdepot.com/brime/ShareX/2021/04/6069749866d36392271037.gif"
                   >
        <vue-plyr
          v-if="clipGenerating === false"
          :options="options"
        >
          <video
            controls
            crossorigin
            playsinline
            autplay
          >
            <source
              size="1080"
              :src="clipVideoUrl"
              type="video/mp4"
            >
          </video>
        </vue-plyr>
        <br v-if="clipGenerating === false">
        <b-button
          v-if="clipGenerating === false"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          size="sm"
          variant="primary"
          :href="twitterShare + clipID"
          target="_blank"
        >
          Share On Twitter
        </b-button>
      </b-card-text>
    </b-modal>
    <b-modal
      id="clipPlayerModal"
      ok-only
      ok-title="Close"
      button-size="sm"
      centered
      size="lg"
      title="Brime Clip ™"
      @hide="selectedClip=''"
    ><vue-plyr :options="options">
       <video
         controls
         crossorigin
         playsinline
         autoplay
       >
         <source
           size="1080"
           :src="`https://vod.brimecdn.com/n/ax9rag1oiyr8/b/brime/o/clip/${selectedClip.channelID}/${selectedClip._id}`"
           type="video/mp4"
         >
       </video>
     </vue-plyr><br>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="primary"
        :href="twitterShare + selectedClip._id"
        target="_blank"
      >
        Share On Twitter
      </b-button>
    </b-modal>
  </div>
</template>

<script>
// import Hls from 'hls.js'
import Vue from 'vue'
import {
  BButton, BAvatar, BModal, BCardText, BButtonGroup, BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend, BForm, BTabs, BTab, BRow, BCard, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { EmojiButton } from '@joeattardi/emoji-button'
import Autocomplete from 'vue2-autocomplete-js'
// eslint-disable-next-line no-unused-vars
import Vue2Filters from 'vue2-filters'
import Sub from './Modules/Sub.vue'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
/* eslint-disable */

function debounce(func, timeout = 250){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Authorization'] = `Bearer ${window.brime.getAccessToken()}`;
      var urlEncode = function(data) {
          var str = [];
          for (var p in data) {
              if (data.hasOwnProperty(p) && (!(data[p] == undefined || data[p] == null))) {
                  str.push(encodeURIComponent(p) + "=" + (data[p] ? encodeURIComponent(data[p]) : ""));
              }
          }
          return str.join("&");
      }
            window.addEventListener("load", () => {
                let timeout = 5 * 1000;
                let count = 150;
                let size = 50;
                let speed = 1;

                const minOpacity = 0.8;
                let avgTickTime = 0;
                let emojis = [];
                let canvas;
                let ctx;

                const targetDelta = 1000 / 60; // 60hz

                function create(c) {
                    const scale = (Math.random() * (1 - minOpacity)) + minOpacity;

                    emojis.push({
                        x: Math.random() * canvas.width,
                        y: Math.random() * canvas.height,
                        ys: Math.random() + 2,
                        scale: scale,
                        opacity: 0.01,
                        text: c,
                        dying: false,
                        time: performance.now()
                    });
                }

                let start = performance.now();

                function draw() {
                    const delta = performance.now() - start;
                    const deltaRate = delta / targetDelta;

                    start += delta;

                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.fillStyle = "white";

                    if (emojis.length > 0) {
                        emojis.forEach((emoji) => {
                            ctx.save();

                            emoji.y += (emoji.ys * deltaRate) * speed;

                            const relativeSize = size * emoji.scale;

                            if ((emoji.time + timeout) < start) {
                                emoji.dying = true;
                            }

                            ctx.globalAlpha = emoji.opacity;

                            // if (emoji.text.startsWith("http")) {
                            const image = new Image();

                            image.src = emoji.text;

                            ctx.drawImage(image, emoji.x, emoji.y, relativeSize, relativeSize);
                            // } else {
                            //     ctx.fillText(emoji.text, emoji.x, emoji.y);
                            // }

                            if (emoji.dying) {
                                if (emoji.opacity > 0.005) {
                                    emoji.opacity -= 0.01;

                                    if (emoji.opacity < 0) {
                                        emoji.opacity = 0;
                                    }
                                } else {
                                    emojis.splice(emojis.indexOf(emoji), 1);
                                }
                            } else if (emoji.opacity < 1) {
                                emoji.opacity += 0.01;
                            }

                            if (emoji.y > (canvas.height + relativeSize)) {
                                emoji.x = Math.random() * canvas.width;
                                emoji.y = -relativeSize;
                            }

                            ctx.restore();
                        });
                    }

                    const time = performance.now() - start;

                    avgTickTime = (avgTickTime + time) / 2;

                    requestAnimationFrame(draw);
                }

                // setInterval(() => {
                //     console.debug(avgTickTime.toFixed(2) + "ms/frame " + emojis.length + " emojis (" + ((avgTickTime / targetDelta) * 100).toFixed(2) + "% of the frame budget)");
                // }, 2000);

                canvas = document.querySelector("#doge-canvas");

                ctx = canvas.getContext("2d");

                ctx.font = size + "px Sans-Serif";

                window.triggerDogeRain = () => {
                    canvas.width = window.innerWidth;
                    canvas.height = window.innerHeight;

                    const audio = new Audio("https://content.brimecdn.com/brime/assets/sub_alert.mp3");

                    audio.addEventListener("play", () => {
                        setTimeout(() => {
                            for (let i = 0; i < count; i++) {
                                create("https://content.brimecdn.com/brime/emote/607fa67d13b66ed6d03de93e/3x");
                            }
                        }, 5300);
                    });

                    audio.volume = 0.15;
                    audio.play();
                }

                requestAnimationFrame(draw);
            });
const getUserData = () => {
  // eslint-disable-next-line no-inner-declarations
  function parseJwt(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))
    return JSON.parse(jsonPayload)
  }
  if (localStorage.getItem('accessToken')) {
    const userData = parseJwt(localStorage.getItem('accessToken'))
    userData.role = 'user'
    // eslint-disable-next-line
      userData.avatar = 'https://i.ibb.co/fMF9pQJ/Doge-Tri-Poloski.png'
    userData.ability = [{ action: 'manage', subject: 'all' }]
    return userData
  }
  return {
    userData: {},
    avatarText: {},
  }
}
const userData = getUserData();
const clientId = localStorage.getItem('accessToken') ? userData.username : null


export default {
  directives: {
    Ripple,
  },
  props: {
    name: {
      type: String,
      default: "CHANNEL NOT FOUND",
    },
  },
  components: {
    BButtonGroup,
    Autocomplete,
    BAvatar,
    BButton,
    BModal,
    BCardText,
    Sub, BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend, BForm, BTabs, BTab, BRow, BCard, BCol,
  },
  mixins: [Vue2Filters.mixin],
  data() {
    return {
      options: {autoplay: true, controls: [
          "play-large",
          "rewind",
          "play",
          "progress",
          "current-time",
          "download",
          "mute",
          "volume",
          "settings",
          "fullscreen",
        ],},
      gameurl: 'https://www.igdb.com/games/',
      following: false,
      followers: null,
      twitterShare: 'http://twitter.com/share?url=https://clips.brimelive.com/',
      title: "",
      category: "",
      clipGenerating: false,
      clipDone: false,
      channelOwner: '',
      isChannelOwner: null,
      vodInfo: {stream: {title:''}},
      channelInfo: {},
      channelVods: [],
      channelClips: [],
      clipVideoUrl: '',
            clips: '',
      clipChannel: '',
      clipChannelStuff: '',
      selectedClip: '',
      twitterShare: 'http://twitter.com/share?url=https://clips.brimelive.com/',
      isSubscribed: true,
      targetCheck: null,
      clipID: null,
      live: true,
      chatUsers: null,
      testURL:'https://players.akamai.com/players/hlsjs?streamUrl=',
      monetized: null,
      verified: false,
      brimeLLSuppported: true,
      resOptions: [],
      chat: [],
      message: "",
      channel: '',
      stream: {},
      chaturl: { url: "https://beta.brimelive.com:8080/" },
      playerOptions: {
        autoplay: true,
        controls: [
          "play-large",
          "rewind",
          "play",
          "progress",
          "current-time",
          "download",
          "mute",
          "volume",
          "settings",
          "fullscreen",
        ],
        settings: ["quality", "speed", "loop"],
      },
    };
  },
  computed: {},
  methods: {
       async deleteVod(clipID) {
      this.player.stop()
     await window.brime.deleteVod(this.vodInfo._id)
      .then(clipID => {
          this.$bvToast.toast(`VOD Deleted. Redirecting you to your channel.`, {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          setTimeout(() => { this.$router.replace(`/${this.userData.username}`) }, 5000)
        })
    },
          async downloadVod() {
     return axios.post(`${window.brime.apiBase}/internal/vod/${this.vodInfo._id}/request-download?client_id=${window.brime.clientId}`, '')
     .then((response) => this.$bvToast.toast(`VOD Download is Processing.`, {
            title: 'Success',
            variant: 'success',
            solid: true,
          }))
    },
  scrolldiv() {
    this.player.pause()
    var offset = 80; // sticky nav height
var el = document.getElementById("viewcount") // element you are scrolling to
window.scroll({ top: (el.offsetTop - offset), left: 0, behavior: 'smooth' })
            },
        capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getDate(clipEpoch) {
      const d = new Date(clipEpoch)
      return d.toLocaleString()
    },
    getDuration(start, end){
        if(end == -1) return 'live'
        return new Date((end - start)).toISOString().substr(11, 8)
    },
    getAgo(start){
      let ago = Math.floor((new Date().getTime() - start) / 1000 / 60)
      if(ago < 60){
        return ago +  'min ago'
      }
      ago = Math.floor(ago / 60)
      if(ago < 24){
        return ago + 'h ago'
      }
      return Math.floor(ago / 24) + 'days ago'
    },
    getDurationClip(start, end){
        if(end == -1) return 'live'
        const timeStamp = new Date((end*1000 - start *1000)).toISOString().substr(11, 8)
       return timeStamp.substr(3);
    },
    getAgoClip(start){
      const secondsSinceEpoch = Math.floor(new Date().getTime())
      let ago = Math.floor((secondsSinceEpoch / 1000 - start))
      if(ago < 60){
        return ago +  'min ago'
      }
      ago = Math.floor(ago / 60)
      if(ago < 24){
        return ago + 'h ago'
      }
      return Math.floor(ago / 24) + 'days ago'
    },
    sendInfo(item) {
      this.selectedClip = item
    },
          openCheckout() {
            this.cbInstance.openCheckout({
              hostedPage: () => {
                let messageBody = {
   plan_id: "tier-1", 
   channel_id: this.channelId,
   channel: this.channel
   }
                return axios.post(`${window.brime.apiBase}/internal/billing/generate_checkout_new_url?client_id=${window.brime.clientId}`, messageBody).then((response) => response.data.data.hosted_page)
              },
              loaded: function() {
              },
              close: function() {
              },
              success: function(hostedPageId) {
               isSubscribed = true
              },
              step: function(value) {
              }
            });
          },
    focusInput() {
     this.$refs.message22.focus();
    },
  async clipIt() {
    this.clipGenerating =  true
    await window.brime.createClip(this.channel)
    .then(data => {
    this.clipVideoUrl = data.clipVideoUrl
    this.clipID = data._id
    this.clipDone = true
    setTimeout(this.clipGenerating = false, 3000)
    })

  },
   async follow() {
      await window.brime.follow(this.channelId)
      this.following = true;
      this.followers = this.followers + 1;
    },
    async unfollow() {
     await window.brime.unfollow(this.channelId)
      this.followers = this.followers - 1;
      this.following = false;
    },
    sendMessage() {
      window.brime.sendChatMessage(this.message, this.channelId)
      this.message = "";
    },
  },
  // created() {
  //   if (localStorage.getItem('userData') !== null) {
  //     this.shotFormSetName = false
  //     this.showFormSendMessage = true
  //   } else {
  //     this.showFormSendMessage = false
  //     this.shotFormSetName = true
  //   }
  // },
  async mounted() {
     
    const href = window.location.pathname;
    const vod = href.substring(href.lastIndexOf('/') + 1)
   
    this.vodInfo = await window.brime.getVodInfo(vod)
    this.channelId = this.vodInfo.channelID
     this.channelVods = await window.brime.getVodsForChannel(this.channelId)
     this.channelClips = await window.brime.getClipsForChannel(this.channelId)
    this.channelInfo = await window.brime.getChannel(this.vodInfo.channelID)
    this.userData = await window.brime.getUser()
    if (userData._id == this.channelInfo.owners[0]) {
     this.isChannelOwner = true
    }
    this.resizeHandler = debounce(()=>{
      document.querySelector('.chat__column').style = `--headerHeight: ${document.querySelector('.navbar-container').clientHeight}px;`
    })

    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)

    this.cbInstance = window.Chargebee.init({site: "brime"});
    document.addEventListener('click', function(e) { if(document.activeElement.toString() == '[object HTMLButtonElement]'){ document.activeElement.blur(); } });
    const emojiOptions = {autoHide: true}
    const picker = new EmojiButton({ initialCategory: 'recents',
      styleProperties: {
    '--font': 'Courier New',
    '--background-color': '#111727',
    '--text-color':'white',
    '--category-button-color':'linear-gradient(90deg,#833ab4,#fd1d1d)'
  },
  custom: [
    {
      name: 'Conga parrot',
      emoji: './site/static/conga_parrot.gif'
    },
    {
      name: 'O RLY?',
      emoji: './site/static/orly.jpg'
    }
  ]
}, emojiOptions);
const trigger = document.querySelector('.trigger');

picker.on('emoji', selection => {
  this.old = this.message
  this.message = this.old + selection.emoji
});

trigger.addEventListener('click', () => picker.togglePicker(trigger));
    this.channel = this.channelInfo._id

if (this.channel === 'TWG2022'){
  this.verified = true
}
if (this.channel === 'zach'){
  this.verified = true
}
if (this.channel === 'geeken'){
  this.verified = true
}
const clientId = localStorage.getItem('accessToken') ? userData.username : null


document.title = `Brime Live`

if (window.brime.getAccessToken()) {
window.brime.isFollowing(this.channelId)
.then(following => {
this.following = following
})
window.brime.isSubscribed(this.channelId)
.then(data => {
this.isSubscribed = data
})
}
const accountData = JSON.parse(localStorage.getItem("userData")) ?? {
  fullName: "null",
  id: "null",
};

this.player = {}
this.player.poster = null

      const self = this;
      this.brimeLLSuppported = false
      const initHLS = () => {
         let video = document.createElement("video");
        video.setAttribute("playsinline", "true");
        video.setAttribute("autoplay", '');
        var parent = document.getElementById("player");
        parent.append(video);

        self.player = new Plyr(video, {
          autoplay: true,
          invertTime: false,
          fullscreen: {
            enabled: true,
            iosNative: true,
          },
          controls: [
          'restart', 
          "play-large",
          "rewind",
          "play",
          'fast-forward',
          "progress",
          "current-time",
          "mute",
          "volume",
          "settings",
          "fullscreen",
          ],
          muted: false,
          storage: {
            enabled: false,
          },
        });

        const source =  this.vodInfo.vodVideoUrl.replace('https://content.brimecdn.com/brime-vods/', 'https://vod.brimecdn.com/n/idmrpurnoj34/b/brime-vods/o/').replace('content.brimecdn.com', 'hls.brimecdn.com')  

          var hls = new Hls();
          hls.loadSource(source);
          hls.attachMedia(video);
if (video.canPlayType("application/x-mpegURL")) {
          video.src = source;
          video.addEventListener("loadedmetadata", function () {});
        }
      };

      const addHLS = () => {
        if (typeof Hls == "undefined") {
          let script = document.createElement("script");
          script.src = "https://cdn.jsdelivr.net/npm/hls.js@latest";
          script.addEventListener("load", initHLS);
          document.head.append(script);
        } else {
          initHLS();
        }
      };

      if (typeof Plyr == "undefined") {
        let script = document.createElement("script");
        script.src = "https://unpkg.com/plyr@3";
        script.addEventListener("load", addHLS);
        document.head.append(script);
      } else if (typeof Hls == "undefined") {
        addHLS();
      } else {
        initHLS();
      }
    function escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
    }

    /*  eslint-disable */
    function parseInitialHTML(html) {
      let parser = document.createElement("div");
      parser.innerHTML = html;
      return parser.children[0];
    }  
    function updateMessage({ username, color, message }) {
      let msg = parseInitialHTML(`
        <li>
       <span class="msg-info-name" data-username></span>
        <span class="msg-text" data-message></span>
    </li>`);
      let user = msg.querySelector("[data-username]");
      user.innerText = username;
      user.style.color = color || "inherit"; //inherit default color if none is set?
      msg.querySelector("[data-message]").innerText = message;
      return msg;
    }
  },
    async beforeCreate() {
    // this.$http.get('').then(res => { this.options = res.data.streams })
    this.href = window.location.pathname;
    const vod = this.href.substring(this.href.lastIndexOf('/') + 1)
      const href = window.location.pathname;
    const vod2 = href.substring(href.lastIndexOf('/') + 1)
   
    this.vodInfo = await window.brime.getVodInfo(vod2)
    this.category = this.vodInfo.stream.category
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resizeHandler)
      this.player.destroy();
  },
};
</script>

<style>
.dark-layout .nav-tabs .nav-item .nav-link.active{
background-color: unset !important;
}
.plyr{
--plyr-color-main: linear-gradient(90deg,#833ab4,#fd1d1d)!important;
}
.chatBadge:not(:first-child) {
    padding-left: 2px;
}
.loadingClip{
width: 100%;
}
.clipVideo{
width: 100%;
}
.clipButton {
margin-top: 5px;
margin-right: 30px;
}
.subButton {
margin-top: 5px;
margin-right: 0px;
}
.followers {
  padding-left: 10px;
  font-size: 14px;
}
.streamTitle {
  padding-top: 5px;
  font-size: 14px;
}
.streamCategory {
  margin-top: -20px;
  margin-left: 55px;
  font-size: 14px;
}

.chat-thread {
  padding-left: 5px;
  padding-top: 7px;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-thread li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 1px 5px 1px 10px;
  margin: 0 0 10px 0;
  font: 16px/20px;
  width: 100%;
}

/* Chat - Speech Bubble Arrow */
.chat-thread li:before {
  position: absolute;
  top: 5px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
}

.chat-thread li {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: left;
  margin-left: 0px;
  color: #fff;
  word-wrap: break-word;
}

.chat-thread li:after {
  border-right: 15px solid transparent;
  right: -15px;
}

.chat-window {
  position: fixed;
  bottom: 12px;
  right: 0;
  width: 100%;
}
.chatInput {
margin-left: 2.5%;
margin-right: 2.5%;
width: 96%;
bottom: 0;
}

.chat-window-message {
  width: 100%;
  height: 48px;
  font: 32px/48px "Noto Sans", sans-serif;
  background: none;
  color: #ffff;
  border: 0;
  border-bottom: 1px solid rgba(25, 147, 147, 0.2);
  outline: none;
  bottom: 0;
  display: fixed;
}

/* Small screens */
@media all and (max-width: 767px) {
  .chat-thread {
    width: 90%;
    height: 230px;
  }
  .chat-window {
    left: 5%;
    width: 90%;
  }
  #sldp-video{
  min-height: unset !important;
  height: unset !important;
  }
.column1 {
  width: 80%;
  margin-left: -15px;
  height: unset !important;
  margin-top: -10px;
}
#channelName{
font-size: 10px !important;
}
.tabSection{
display: none;
}
.followCountButton{
display: none;
}
.clipIcon{
display: none;
}
.actionButtons{
right: -15px !important;
position: fixed;
}
.badge-minimal{
display: none;
}
#channel{
font-size: 14px !important;
margin-top: 15px !important;
margin-left: 2px !important;
}
.viewcount{
font-size: 12px !important;
}
.streamCategory{
margin-top: 2px !important;
margin-left: 0 !important;
}
}
/* Medium and large screens */
@media all and (min-width: 768px) {
  .chat-thread {
    width: 100%;
    height: 85vh;
  }
#player{
  /* override other styles to make responsive */
  width: 100%    !important;
  min-height:  84vh !important; 
}
  .chat-window {
    width: 100%;
  }
}
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
</style>
<style scoped>
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}

.card img {
  transition: all 0.2s;
}
.card{
box-shadow: none;
padding: 0.5rem;
}
.vod__category{
color: #b8c2cc;
}
.card-body{
padding: unset !important;
}
.card{
background-color: unset !important;
transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-4px);
}

.card:hover .vod__image {
  opacity: 1;
}

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
}

.vod__image {
  position: relative;
  opacity: 1.0;
  transition: opacity 0.2s;
}

.vod__duration {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.vod__category, .vod__date {
  font-size: 0.9em;
}

.tabSection{
margin-top: 8vh;
margin-left: 1vw;
margin-right: 1vw;
}
.vodSection{
margin-top: 5vh;
}
#sldp-video{
  /* override other styles to make responsive */
  width: 100%    !important;
}
.btn {
margin-left: 10px !important;
}
.disabled{
margin-left: 0px !important;
}
.actionButtons{
margin-top: -50px;
margin-right: 20px;
}
.creatorMenu{
margin-top: 10px;
}
#doge-canvas {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                pointer-events: none;
                z-index: 9001;
}
.disable-scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}
html {
  overflow:   scroll;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
#channel {
  margin-left: 15px;
  margin-top: 15px;
  top: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
}
#channel img {
  border-radius: 50%;
  vertical-align: middle;
}
button {
  margin-left: 20px;
}
.column1 {
  width: 80%;
  margin-left: -15px;
  height: 80vh;
  margin-top: -5px;
}
.column {
  width: 20%;
  flex: 1;
  background: #111727;
  margin-right: -12px;
  padding-bottom: 2vh;
  height: 100%;
}
.row {
  margin-top: -25px !important;
}
.channel__row {
  flex-wrap: nowrap;
}

#player {
  flex-shrink: 0;
}
.content-body
{
height: 100% !important;
}

@media screen and (min-width: 769px){
  .chat__column {
    position: fixed;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    margin: 0;
    top: var(--headerHeight);
    height: calc(100% - var(--headerHeight));
    padding-bottom: 1rem;
  }

  .chat-thread {
    flex: 1;
  }

  #chat-form {
    flex-shrink: 0;
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .column1 {
    width: 100%;
    margin-left: 0px;
    margin: 0;
    padding: 0;
  }

  .channel__row {
    flex-wrap: wrap;
  }

  .chat__column {
    width: 100%;
    flex-shrink: 0;
    margin: 0;
  }

  .chat__column form {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .chat-thread {
    width: 100%;
  }

  .content {
    padding: 0px;
  }

  #player {
    max-height: 80%;
  }
}
</style>